import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  LinkTo,
  VideoPlayer,
  Actions
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Eye from '../../assets/images/icons/eye.svg';
import Money from '../../assets/images/icons/money.svg';
import Website from '../../assets/images/icons/website.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import Touch from '../../assets/images/icons/touch.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';

const Scalability = () => {
  return (
    <PageLayout>
      <SEO
        title="Unmatched Blockchain Scalability Performance"
        description="Experience unmatched blockchain scalability to handle large business and enterprise volumes of transactions while remaining affordable"
      />
      <Layout dark background="blue-dark">
        <Hero type="code">
          <div className="caption">Flexible scalability</div>
          <Title color="white">
            Avoid network congestion and unpredictable fees
          </Title>
          <Description color="blue-light">
            Experience unmatched blockchain scalability to handle large volumes of transactions while
            remaining affordable to deploy.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Website,
                title: 'Affordable transaction costs',
                body:
                  'A business can reduce deterministic and predictable transaction fees to as low as twenty five ten millionths of a dollar ($0.0000025) per transaction.',
              },
              {
                Icon: Flexible,
                title: 'Protection',
                body:
                  'Allow network to network and interdepartmental communications with Interchain and interoperability.',
              },
              {
                Icon: Money,
                title: 'Independently scalable',
                body:
                  'Every node has its own blockchain and every application or system is independently scalable.',
              },
              {
                Icon: Eye,
                title: 'Security',
                body:
                  'An attack on this system would require a simultaneous attack on all of the Interchained networks.',
              },
              {
                Icon: RewardB,
                title: 'Interchain',
                body:
                  'Scale your interoperable system by aggregating multiple blocks into a single Interchain transaction on a cadence.',
              },
              {
                Icon: Touch,
                title: 'Decentralized',
                body:
                  'Every transaction is immediately usable on a trust basis, and in time is provable to an increasing level of decentralized consensus..',
              },
            ]}
          />
          <Row auto span={12} gap="large">
            <Column>
              <Section
                caption="A quarter of a billion transactions."
                title="Executed fully on an operational network."
              >
                <p>
                  Dragonchain’s hybrid architecture enables scalability in flexible and innovative ways. Every
                  transaction is protected on your private business blockchain. Meanwhile the business node
                  may be held completely private, or may be exposed or replicated to any level of public
                  exposure desired.
                </p>
                <br />
                <p>
                  Dragonchain established Context Based Verification as its consensus model. Every business
                  transaction is immediately usable on a trust basis, and in time is provable to an increasing
                  level of decentralized consensus. A transaction has a level of decentralization to
                  independently owned and deployed Dragonchain nodes on Dragon Net (~700 nodes) within
                  seconds, along with complimentary decentralization and security to Bitcoin and Ethereum
                  within minutes or hours, at no extra cost.
                </p>
              </Section>
            </Column>
            {/* TODO: this needs to be in a column */}
            <VideoPlayer
              url="https://www.youtube.com/watch?v=pSfBid1_US4"
              caption="256 million transactions in 24 hours"
            />
          </Row>
          <Row gap="large" /> {/* TODO: remove after video player fix */}
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="What is Context Based Verification?">
                  <LinkTo type="route" blank to="https://dragonchain.com/Dragonchain-Architecture.pdf">
                    To learn more about context based verification, explore the original 2016 Dragonchain
                    architecture document.
                  </LinkTo>
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={7}>
              <Section title="Deploy blockchain applications at scale today.">
                Looking for a scalable and affordable open source blockchain platform you can trust? Let
                Dragonchain show you the private and secure, scalable and cost effective solutions and
                services we have ready for you today.
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default Scalability;
